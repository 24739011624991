import { styled } from '@mui/material/styles'
import { colors } from '../../styles/colorScheme'

export const Layout = styled('div')<{ background?: 'transparent' }>(({ background }) => ({
  position: 'relative',
  backgroundColor: colors.black,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '100vh',
}))
