

import { pxToRem } from '@/common/utils'
import { colors } from './colorScheme'

const MuiAvatar = {
  variants: [
    {
      props: { variant: 'rounded' },
      style: {
        width: pxToRem(18),
        height: pxToRem(18),
      },
    } as const,
  ],
  styleOverrides: {
    root: {
      borderRadius: '50%',
      fontSize: pxToRem(11),
      // backgroundColor: colors.neutral['200'],
    },
  } as const,
}

export default MuiAvatar
