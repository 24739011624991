import { ReactComponent as MempoolsLogo } from '@/assets/icons/mempools-logo-light.svg'
import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { magic } from '@/contexts/magic'
import { Avatar } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Container, Logo, SignStyledButton } from './Navbar.styles'

interface Props {
  showSignButton?: boolean
}

export default function Navbar({ showSignButton = true }: Props) {
  const { userEmail, setUser } = useAuth() as AuthContextType
  const router = useRouter()

  const handleSignOut = () => {
    magic.user.logout().then(() => {
      router.push('/login')
      setUser({ user: null })
    })
  }

  return (
    <Container className={!showSignButton ? 'loginPage' : ''}>
      <Link href={'/'}>
        <Logo>
          <MempoolsLogo />
        </Logo>
      </Link>
      {showSignButton && (
        <SignStyledButton
          onClick={handleSignOut}
          variant={'outlined'}
          sx={{
            '&:hover': {
              transition: 'all 0.2s ease-in-out',
              backgroundColor: '#FFFFFF3D',
              border: '1px solid #FFFFFF3D',
            },
          }}
        >
          <Avatar variant="rounded"> {userEmail?.at(0)?.toUpperCase()} </Avatar>
          Sign out
        </SignStyledButton>
      )}
    </Container>
  )
}
