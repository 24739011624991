import SnackbarAlert from '@/common/components/Snackbar/SnackbarAlert'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SnackBarContextType } from './SnackbarContextType'

interface Props {
  children: ReactNode
}

type AlertProps = {
  severity: 'success' | 'error' | 'info' | 'warning'
  message: string
  autoDismiss: number
}

const AUTO_DISMISS = 5000

const SnackBarContext = createContext<SnackBarContextType | null>(null)
export const useSnackbar = () => useContext(SnackBarContext)

export function SnackBarProvider({ children }: Props) {
  const [alerts, setAlerts] = useState<AlertProps[]>([])
  const [open, setOpen] = React.useState(true)

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setAlerts([])
    setOpen(false)
  }

  const activeAlertIds = alerts.join(',')
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts(alerts => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS
      )
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const addAlert = useCallback((alert: AlertProps) => {
    const newAlerts = [alert]
    setAlerts(newAlerts)
    setOpen(true)
  }, [])

  const value = useMemo(() => ({ addAlert }), [addAlert])

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert: AlertProps, index: number) => (
        <SnackbarAlert
          severity={alert.severity}
          message={alert.message}
          autoDismiss={AUTO_DISMISS}
          key={index}
          handleClose={handleClose}
          open={open}
        />
      ))}
    </SnackBarContext.Provider>
  )
}
