const MuiTextField = {
    styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            '& .MuiOutlinedInput-root': {
                border: 'none',
            }
          }
        },
      } as const,
}

export default MuiTextField