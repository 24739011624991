const MuiCheckbox = {
  styleOverrides: {
    root: {
      color: 'white',
      '&.Mui-checked': {
        color: '#61E89F',
      },
    },
  },
}

export default MuiCheckbox
