import { pxToRem } from '@/common/utils'

const MuiAlert = {
  styleOverrides: {
    root: {
      fontSize: pxToRem(16),
      borderRadius: pxToRem(8),
      boxShadow: 'none',
      '& .MuiAlert-icon': {
        fontSize: pxToRem(24),
      },
      '& .MuiAlert-action': {
        padding: 0,
        marginLeft: pxToRem(6),
        alignItems: 'center',
      },
    } as const,
    standardSuccess: {
      backgroundColor: '#61E89F3D',
      color: '#fff',
      '& .MuiAlert-icon': {
        color: '#61E89F',
      },
    },
    standardError: {
      backgroundColor: '#FF4D6C3D',
      color: '#fff',
      '& .MuiAlert-icon': {
        color: '#FF4D6C',
      },
    },
    standardInfo: {
      backgroundColor: 'transparent',
      color: '#fff',
      border: '1px solid #FFFFFF1F',
      '& .MuiAlert-icon': {
        color: '#FFFFFF7A',
      },
    },
    standardWarning: {
      backgroundColor: '#FFEA7C3D',
      color: '#fff',
      '& .MuiAlert-icon': {
        color: '#FFEA7C',
      },
    },
  },
}

export default MuiAlert
