import { typography } from './typography'
import { colors } from './colorScheme'
import { color } from '@mui/system'
import { pxToRem } from '@/common/utils'

const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      width: '100%',
      boxShadow: 'none',
      whiteSpace: 'nowrap',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        // backgroundColor: colors.neutral['100'],
        // color: colors.neutral['50'],
      },
      '& .MuiSvgIcon-root': {
        color: 'currentColor',
      },
    } as const,
    sizeLarge: {
      // ...typography.buttonL,
      padding: `${pxToRem(16)} ${pxToRem(24)}`,
      height: pxToRem(48),
    },
    sizeMedium: {
      // ...typography.buttonS,
      height: pxToRem(40),
      padding: `${pxToRem(12)} ${pxToRem(24)}`,
    },
    sizeSmall: {
      // ...typography.buttonS,
      height: pxToRem(32),
      padding: `${pxToRem(8)} ${pxToRem(24)}`,
    },
    containedPrimary: {
      // backgroundColor: colors.neutral['900'],
      // color: colors.neutral['50'],
      '&:hover': {
        // backgroundColor: colors.neutral['700'],
      },
      '&:active': {
        // backgroundColor: colors.neutral['700'],
      },
    },
    containedSecondary: {
      // backgroundColor: colors.neutral['50'],
      // color: colors.neutral['900'],
      '&:hover': {
        // backgroundColor: colors.neutral['100'],
      },
      '&:active': {
        // backgroundColor: colors.neutral['100'],
      },
    },
    containedError: {
      // backgroundColor: colors.red['500'],
      // color: colors.neutral['50'],
      '&:active': {
        // backgroundColor: colors.red['600'],
      },
      '&:hover': {
        // backgroundColor: colors.red['600'],
      },
    },
    outlined: {
      borderWidth: pxToRem(1),
      borderStyle: 'solid',
      borderColor: colors.white[48],
      borderRadius: pxToRem(8),
      backgroundColor: 'transparent',
      color: colors.white.primary,
      ...typography.buttonRegular,
      '&:active': {
        // borderColor: colors.neutral['50'],
        // backgroundColor: colors.neutral['50'],
      },
      '&:hover': {
        borderColor: colors.red[24],
        // backgroundColor: colors.neutral['50'],
      }
    },
    outlinedError: {
      borderWidth: pxToRem(1),
      borderStyle: 'solid',
      // borderColor: colors.red['100'],
      backgroundColor: 'transparent',
      // color: colors.red['500'],
      '&:active': {
        // borderColor: colors.red['50'],
        // backgroundColor: colors.red['50'],
      },
      '&:hover': {
        // borderColor: colors.red['50'],
        // backgroundColor: colors.red['50'],
      },
    },
    endIcon: {
      width: pxToRem(16),
      height: pxToRem(16),
      margin: 0,
      svg: {
        fill: 'none',
        path: {
          stroke: 'currentColor',
          strokeWidth: '2',
        },
      },
    },
    startIcon: {
      margin: 0
    },
  } as const,
}

export default MuiButton
