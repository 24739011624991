export const colors = {
  green: {
    'primary': '#61E89F',
    '48': '#61E89F7A',
    '24': '#61E89F3D',
  },
  red: {
    'primary': '#FF4D6C',
    '48': '#FF4D6C3D',
    '24': '#FF4D6C3D',
  },
  yellow: {
    'primary': '#FFEA7C',
    '48': '#FFEA7C7A',
    '24': '#FFEA7C3D',
  },
  white: {
    'primary': '#FFF',
    '48': '#FFFFFF7A',
    '24': '#FFFFFF3D',
    '12': '#FFFFFF1F'
  },
  gray: {
    'primary': '#3D3D3D'
  },
  black: '#000',
} as const
