import LoadingLayout from '@/layouts/LoadingLayout'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { AuthContextType, AuthUserType } from './AuthContextType'
import { magic } from './magic'

interface Props {
  children: ReactNode
}

const AuthContext = createContext<AuthContextType | null>(null)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: Props) => {
  const router = useRouter()
  const [user, setUser] = useState<AuthUserType>({ user: null })
  const PUBLIC_ROUTE = router.pathname !== '/login'
  const [loading, setLoading] = useState<boolean>(true)

  const checkUser = useCallback(async () => {
    const isLoggedIn = await magic.user.isLoggedIn()
    if (isLoggedIn) {
      const userToken = await magic.user.getIdToken()
      const user = await magic.user.getMetadata()
      setUser({
        user: {
          userEmail: user.email,
          isValid: isLoggedIn,
          userToken: userToken,
          userId: user.issuer,
        },
      })
    } else {
      setUser({ user: null })
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    checkUser()
  }, [checkUser])

  useEffect(() => {
    if (!loading) {
      if ((!user.user?.userToken || !user.user?.isValid || !user.user?.userId) && PUBLIC_ROUTE) {
        router.push('/login')
      }

      if (user.user?.isValid && router.pathname === '/login') {
        router.push('/')
      }
    }
  }, [user, router, PUBLIC_ROUTE, loading])

  return (
    <AuthContext.Provider
      value={{
        isValid: user.user?.isValid || false,
        userEmail: user.user?.userEmail || null,
        userToken: user.user?.userToken || null,
        userId: user.user?.userId || null,
        setUser,
      }}
    >
      {loading ? <LoadingLayout /> : children}
    </AuthContext.Provider>
  )
}
