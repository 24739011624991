import { styled } from '@mui/material/styles'

import { colors } from '../../../styles/colorScheme'
import { Box, Button } from '@mui/material'
import { pxToRem } from '@/common/utils'

export const Container = styled('div')<{ background?: 'transparent' }>(({ background }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.white.primary,
  zIndex: 2,
  '&.loginPage': {
    justifyContent: 'center',
  }
}))

export const Logo = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  gap: pxToRem(10),
  alignItems: 'center',
  svg: {
    width: pxToRem(154.7),
    height: pxToRem(32),
    path: {
      stroke: 'none',
    },
  }
}))

export const SignStyledButton = styled(Button)(() => ({
  padding: `${pxToRem(6)} ${pxToRem(12)}`,
  width: 'auto',
  gap: pxToRem(8),
}))
